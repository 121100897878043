import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledSubscribed = styled.section`
  padding: 60px 0;

  ${breakpoint.medium`
    padding: 124px 0;
  `}

	h2 {
    margin: 2rem 0 0;
	}

  h3 {
    color: ${colors.purpleDark};
    margin: 3rem 0 0;
    font-size: 24px;
  } 
  
  h4 {
		margin: 2rem 0 0;
    font-size: 21px;
	}

	p {
		text-align: left;
    margin: 1rem 0 0;
  }
  
  b { font-weight: 600;}

  ul, ol {
    margin: 10px 10px 10px 20px;
    list-style: revert;
  }

  li {
    margin: 10px;
  }

	a {
		color: ${colors.orange};

		&:hover,
		&:active {
		color: ${colors.purpleDark};
		}
	}
`

const Subscribed = () => {
  return (
    <StyledSubscribed>
      <Container data-aos="fade-up">
        <h2>Almost finished!</h2>
        <p>Please check your inbox for a confirmation email from Team Visby and click the button to confirm your subscription.</p>
        <p>While you are there, please whitelist our email address and check your junk/spam folder.</p>
      </Container>

      <Container data-aos="fade-up">
        <h3>Whitelist Email Address Resources</h3>
        <h4>How to Whitelist an Email in Outlook</h4>
        <ol>
          <li>Click the gear icon in Outlook and select <b>View all Outlook settings</b>.</li>
          <li>Select <b>Mail</b> from the settings menu.</li>
          <li>Choose <b>Junk email</b> from the submenu.</li>
          <li>Click <b>Add</b> under <b>Safe senders and domains</b>.</li>
          <li>Enter the email address that you want whitelisted.</li>
          <li>Choose <b>Save</b>.</li>
        </ol>
      </Container>

      <Container data-aos="fade-up">
        <h4>How to Whitelist an Email in Gmail</h4>
        <ol>
          <li>Click the gear icon in Gmail and select <b>See all settings</b> from the Quick settings menu.</li>
          <li>Navigate to <b>Filters and Blocked Addresses</b> in the top menu.</li>
          <li>Select <b>Create a new filter</b>.</li>
          <li>Add a specific email or a whole domain in the <b>From</b> field.</li>
          <li>Click <b>Create filter</b>.</li>
          <li>Check <b>Never send it to Spam</b> in the checkbox.</li>
          <li>Click <b>Create filter</b> again.</li>
        </ol>
      </Container>

      <Container data-aos="fade-up">
        <h3>Whitelisting Across Email Providers</h3>
        <p>The whitelisting process differs between email providers. Refer to your email provider’s associated help documentation for information on whitelisting on your client of choice:</p>
        <ul>
          <li><a href="https://support.apple.com/en-us/HT204137" target="_blank" rel="noopener noreferrer">Whitelisting in Apple Mail</a></li>
          <li><a href="https://support.office.com/en-us/article/Block-senders-or-unblock-senders-in-Outlook-on-the-web-9bf812d4-6995-4d19-901a-76d6e26939b0" target="_blank" rel="noopener noreferrer">Whitelisting in Exchange 365 and Outlook 2016</a></li>
          <li><a href="https://support.google.com/a/answer/60751?hl=en" target="_blank" rel="noopener noreferrer">Whitelisting in Gmail</a></li>
          <li><a href="https://support.office.com/en-us/article/block-or-allow-junk-email-settings-48c9f6f7-2309-4f95-9a4d-de987e880e46#bkmk_safesenders" target="_blank" rel="noopener noreferrer">Whitelisting in Outlook Connected to Microsoft Exchange Server 2013 and 2010</a></li>
          <li><a href="https://help.yahoo.com/kb/sln24439.html" target="_blank" rel="noopener noreferrer">Whitelisting in Yahoo! Mail</a></li>
        </ul>
      </Container>


    </StyledSubscribed>
  )
}
export default Subscribed


