import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/join-our-list/hero"
import Subscribed from "./screens/join-our-list/subscribed"

const JoinThanks = () => {
  return (
    <React.Fragment>
      <Hero />
      <Subscribed />
    </React.Fragment>
  )
}
export default JoinThanks

export const Head = () => (
  <Seo
        title="Join our List"
        description="Subscribe to the Visby Medical mailing list."
		image="/meta/join-our-list.jpg"
  />
)
